import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7586da93 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _382d26dd = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _01c34297 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _3de93c4a = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _2f6feba7 = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _3317b523 = () => interopDefault(import('../pages/vencedores.vue' /* webpackChunkName: "pages/vencedores" */))
const _37cb5701 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _69c13a4a = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _e45c64cc = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _178fa81d = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _1aa1aed5 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _17b693fa = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _6e5ddce8 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _4e3bf27a = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _cdc0eb4c = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _668d6f44 = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _a0d85b6e = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _51698878 = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _01dab3f0 = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _1688c948 = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _3a821833 = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _705c89de = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _24e206e6 = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _743013b2 = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _2707286b = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _2b722bec = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _40af913c = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _452ac08c = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _e8af3550 = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _322bdcaf = () => interopDefault(import('../pages/admin/winners-draw/index.vue' /* webpackChunkName: "pages/admin/winners-draw/index" */))
const _d963a42a = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _f3f4245e = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _2a470d6b = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _6830dc6b = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _4ad36f51 = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _06d03604 = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _79056ab2 = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _9af3e8b8 = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _27f6405e = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _6a879c52 = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _f03cc89c = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _3b0403ef = () => interopDefault(import('../pages/admin/winners-draw/create.vue' /* webpackChunkName: "pages/admin/winners-draw/create" */))
const _4f5f4c13 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a12de8fc = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _30eaddeb = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _0cc70a5d = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _3c3cf16c = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _0d02622a = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _6da81be8 = () => interopDefault(import('../pages/admin/winners-draw/edit/_id.vue' /* webpackChunkName: "pages/admin/winners-draw/edit/_id" */))
const _4ed5b4ae = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _a425663c = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _f5585a1e = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _702f48e0 = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _180e0c64 = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _57e2e94a = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _6a9f6bc6 = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _396703ac = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _c6b985fc = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _6e11a87d = () => interopDefault(import('../pages/unsubscribe/_id.vue' /* webpackChunkName: "pages/unsubscribe/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _7586da93,
    name: "admin"
  }, {
    path: "/cupom",
    component: _382d26dd,
    name: "cupom"
  }, {
    path: "/faq",
    component: _01c34297,
    name: "faq"
  }, {
    path: "/perfil",
    component: _3de93c4a,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _2f6feba7,
    name: "regulamentos"
  }, {
    path: "/vencedores",
    component: _3317b523,
    name: "vencedores"
  }, {
    path: "/admin/actions",
    component: _37cb5701,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _69c13a4a,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _e45c64cc,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _178fa81d,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _1aa1aed5,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _17b693fa,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _6e5ddce8,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _4e3bf27a,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _cdc0eb4c,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _668d6f44,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _a0d85b6e,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _51698878,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _01dab3f0,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _1688c948,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _3a821833,
    name: "admin-prizes"
  }, {
    path: "/admin/registrations",
    component: _705c89de,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _24e206e6,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _743013b2,
    name: "admin-statistics"
  }, {
    path: "/admin/tickets",
    component: _2707286b,
    name: "admin-tickets"
  }, {
    path: "/admin/tokens",
    component: _2b722bec,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _40af913c,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _452ac08c,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _e8af3550,
    name: "admin-winners"
  }, {
    path: "/admin/winners-draw",
    component: _322bdcaf,
    name: "admin-winners-draw"
  }, {
    path: "/forgot",
    component: _d963a42a,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _f3f4245e,
    name: "auth-login"
  }, {
    path: "/register",
    component: _2a470d6b,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _6830dc6b,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _4ad36f51,
    name: "admin-auth-login"
  }, {
    path: "/admin/dispatches/create",
    component: _06d03604,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _79056ab2,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _9af3e8b8,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _27f6405e,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _6a879c52,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _f03cc89c,
    name: "admin-users-create"
  }, {
    path: "/admin/winners-draw/create",
    component: _3b0403ef,
    name: "admin-winners-draw-create"
  }, {
    path: "/",
    component: _4f5f4c13,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _a12de8fc,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _30eaddeb,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _0cc70a5d,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _3c3cf16c,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _0d02622a,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/winners-draw/edit/:id",
    component: _6da81be8,
    name: "admin-winners-draw-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _4ed5b4ae,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _a425663c,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _f5585a1e,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _702f48e0,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _180e0c64,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _57e2e94a,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _6a9f6bc6,
    name: "admin-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _396703ac,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _c6b985fc,
    name: "auth-reset-token"
  }, {
    path: "/unsubscribe/:id?",
    component: _6e11a87d,
    name: "unsubscribe-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
